import { render, staticRenderFns } from "./EvaluationCriteriaMacroComponent.vue?vue&type=template&id=352d634d&scoped=true"
import script from "./EvaluationCriteriaMacroComponent.vue?vue&type=script&lang=js"
export * from "./EvaluationCriteriaMacroComponent.vue?vue&type=script&lang=js"
import style0 from "./EvaluationCriteriaMacroComponent.vue?vue&type=style&index=0&id=352d634d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352d634d",
  null
  
)

export default component.exports